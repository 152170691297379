<template>
  <v-card v-if="timeline.length" class="mb-6">
    <div v-if="loading" class="py-3">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <v-stepper v-else non-linear v-model="stepper" class="timeline_container">
      <v-stepper-header>
        <template v-for="(group, key) in timeline">
          <v-stepper-step
            editable
            :step="key + 1"
            :key="'group-' + group.id"
            :complete="completedPercentages[key] === 100"
            edit-icon="$complete"
            @click="onStepperClick(key)"
            v-bind:class="{ clickedService: clickEvent(stepper, key) }"
          >
            <v-progress-circular
              class="mx-auto display-mobile"
              :rotate="180"
              :size="60"
              :width="minimal ? 4 : 7"
              :value="completedPercentages[key]"
              :color="checkDeadlinePassedCloseOrCompleted(group, key).color"
            >
              <div
                :class="checkDeadlinePassedCloseOrCompleted(group, key).class"
              >
                {{ completedPercentages[key] + "%" }}
              </div>
            </v-progress-circular>
            <div class="mx-auto main-service-header text-center">
              <div
                class="main-service-header"
                v-bind:class="{ active: true, minimal: minimal }"
                :style="minimal ? minimalStyle : {}"
              >
                <div style="margin-left: 3px; margin-right: 3px">
                  <div class="sub_task_number">
                    {{ group.order }}
                  </div>
                  {{ group.name }}
                </div>
              </div>
              <small
                v-if="!minimal && !isAllSubCompleted(group.services)"
                class="mt-1"
                style="color: #473068; font-size: 10px"
                >{{ formatDate(group.services.at(-1).deadline) }}</small
              >
              <small
                v-else-if="!minimal"
                class="mt-1"
                style="color: #473068; font-size: 10px"
                >{{
                  formatDate(
                    group.services.at(-1).sub_services.at(-1).completed_at
                  )
                }}</small
              >
            </div>
          </v-stepper-step>
          <v-divider
            v-if="key !== timeline.length - 1"
            :key="'step' + group.id"
            v-bind:class="{ minimal: minimal }"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items v-if="!minimal">
        <v-stepper-content
          :step="key + 1"
          v-for="(group, key) in timeline"
          :key="'content' + group.id"
        >
          <v-card class="subtasks_circulars_container">
            <v-card-title class="subtask_titles"
              >{{ group.name }}
              <v-chip class="ml-6 v-chip-color" outlined>
                <alarm-icon
                  v-if="completedPercentages[key] !== 100"
                  class="mr-1"
                />
                <alarm-icon v-else class="mr-1" />
                <span class="status_alignment">
                  {{ calculateDaysTaken(group, key) }}
                </span>
              </v-chip>
            </v-card-title>

            <v-row class="mt-2 ml-2">
              <v-col cols="4" lg="3" md="3" sm="4">
                <div
                  class="dates_container"
                  v-if="!isAllSubCompleted(group.services)"
                >
                  <span class="subservices_dates_titles">Deadline:</span>
                  <span class="subservices_dates">
                    {{ formatDate(group.services.at(-1).deadline) }} ({{
                      formatTimeAgo(group.services.at(-1).deadline)
                    }})
                  </span>
                </div>
                <div v-else class="dates_container">
                  <span class="subservices_dates_titles">Completed on:</span>
                  <span class="subservices_dates">
                    {{
                      formatDate(
                        group.services.at(-1).sub_services.at(-1).completed_at
                      )
                    }}
                  </span>
                </div>
              </v-col>
              <v-col cols="4" lg="3" md="3" sm="4">
                <div class="dates_container">
                  <span class="subservices_dates_titles">Created at:</span>
                  <span class="subservices_dates">
                    {{ formatTime(group.services_created_at) }}
                  </span>
                </div>
              </v-col>
              <v-col cols="4" lg="3" md="3" sm="4">
                <div class="dates_container">
                  <span class="subservices_dates_titles">Updated at:</span>
                  <span class="subservices_dates">
                    {{ formatTime(group.services_updated_at) }}
                  </span>
                </div>
              </v-col>
              <v-progress-linear
                v-if="changingStatus"
                class="mb-2 ml-10 mr-10"
                color="#473068"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-row>
            <v-card-text>
              <div
                v-for="service in group.services"
                :key="'service-' + service.id"
              >
                <div v-if="group.services.length > 1" class="service-header">
                  {{ service.service_details.name }}
                </div>
                <div
                  v-if="!service.sub_services.length"
                  class="timeline_container no-activities-parent"
                >
                  <div class="no-activities-text">No activities</div>
                </div>
                <v-stepper
                  v-else
                  non-linear
                  v-model="subStepper"
                  vertical
                  class="timeline_container"
                >
                  <template v-for="(sub_service, key) in service.sub_services">
                    <v-stepper-step
                      editable
                      :step="subStepper !== key + 1 ? key + 1 : 'Crt.'"
                      :key="'substep' + sub_service.id"
                      :complete="sub_service.status === 3"
                      edit-icon="$complete"
                      @click="
                        subStepperClick(
                          sub_service.deadline,
                          sub_service.time_period,
                          key,
                          sub_service.id
                        )
                      "
                    >
                      <div class="sub-service-header">
                        <v-progress-circular
                          v-if="checkDeadlinePassedSubService(sub_service)"
                          class="display-mobile"
                          :size="50"
                          :width="6"
                          value="100"
                          color="#FC3766"
                        >
                          <div v-if="sub_service.status !== 3">
                            {{ key + 1 }}
                          </div>
                          <v-icon style="color: #fc3766 !important" v-else>
                            mdi-check</v-icon
                          >
                        </v-progress-circular>
                        <v-progress-circular
                          v-else
                          class="display-mobile"
                          :size="50"
                          :width="6"
                          value="100"
                          color="#70D0CE"
                        >
                          <div v-if="sub_service.status !== 3">
                            {{ key + 1 }}
                          </div>
                          <v-icon color="#70D0CE" v-else> mdi-check</v-icon>
                        </v-progress-circular>
                        <div class="my-auto sub-service-header-text">
                          <h4 class="sub-service-name">
                            {{
                              sub_service.sub_service_details &&
                              sub_service.sub_service_details.name
                            }}
                          </h4>

                          <h6
                            v-if="sub_service.completed_at !== null"
                            class="mt-1 sub-service-date"
                          >
                            {{
                              "Completed on " +
                              formatDate(sub_service.completed_at)
                            }}
                          </h6>
                          <h6 v-else class="mt-1 sub-service-date">
                            {{ "Deadline " + formatDate(sub_service.deadline) }}
                          </h6>
                        </div>
                        <div
                          v-if="statusUpdatingId === sub_service.id"
                          class="loading-bar-container"
                        >
                          <md-progress-bar
                            class="bar_color"
                            md-mode="indeterminate"
                          ></md-progress-bar>
                        </div>
                        <div v-else>
                          <v-menu bottom offset-y class="status-menu">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                class="v-chip-color"
                                :color="
                                  sub_service.status === 3
                                    ? '#492a67'
                                    : sub_service.status === 2
                                    ? '#c6a8ec'
                                    : '#EA7A66'
                                "
                                v-bind="attrs"
                                v-on="on"
                                :disabled="statusUpdatingId"
                                outlined
                              >
                                <status-icon class="mr-1" />
                                <span class="status_alignment">
                                  {{
                                    sub_service.status === 3
                                      ? "Completed"
                                      : sub_service.status === 2
                                      ? "In progress"
                                      : "Not started"
                                  }}
                                </span>
                              </v-chip>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, i) in statuses.filter(
                                  (status) =>
                                    status.value !== sub_service.status
                                )"
                                :key="i"
                                @click="
                                  changeStatus(item.value, sub_service.id)
                                "
                              >
                                <v-list-item-title
                                  >{{ item.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </div>
                    </v-stepper-step>
                    <v-stepper-content
                      :step="key + 1"
                      :key="'subcontent' + sub_service.id"
                    >
                      <v-progress-linear
                        v-if="changingStep"
                        class="mb-2"
                        color="#473068"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>

                      <v-row v-if="selectedSubServiceId == sub_service.id">
                        <v-col cols="10">
                          <v-row>
                            <v-col class="days">
                              <div>
                                <div class="subservices_dates_titles">
                                  Created at:
                                </div>
                                <div class="mt-1 subservices_dates">
                                  {{ formatTime(sub_service.created_at) }}
                                </div>
                              </div>
                              <div class="material-icons display-icons">
                                arrow_forward_ios
                              </div>
                            </v-col>

                            <v-col class="days">
                              <div>
                                <div class="subservices_dates_titles">
                                  Updated at:
                                </div>
                                <div class="mt-1 subservices_dates">
                                  {{ formatTime(sub_service.updated_at) }}
                                </div>
                              </div>
                              <div class="material-icons display-icons">
                                arrow_forward_ios
                              </div>
                            </v-col>

                            <v-col class="days">
                              <div>
                                <div class="subservices_dates_titles">
                                  Deadline:
                                </div>
                                <div class="mt-1 subservices_dates">
                                  {{ formatDate(sub_service.deadline) }} ({{
                                    formatTimeAgo(sub_service.deadline)
                                  }})
                                </div>
                              </div>
                            </v-col>

                            <div class="material-icons display-icons">
                              arrow_forward_ios
                            </div>
                            <v-col class="days">
                              <div>
                                <div class="subservices_dates_titles">
                                  Time period:
                                </div>
                                <div class="mt-1 subservices_dates">
                                  <v-autocomplete
                                    :items="numberList()"
                                    v-model="newTimePeriod"
                                    :disabled="
                                      sub_service.status === 3 ||
                                      deadlineUpdatingId ||
                                      timePeriodUpdatingId
                                    "
                                    @change="changeTimePeriod(sub_service)"
                                    :loading="
                                      timePeriodUpdatingId === sub_service.id
                                    "
                                  >
                                  </v-autocomplete>
                                </div>
                              </div>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="4">
                              <div class="sub-service-content">
                                <h5
                                  style="color: #70d0ce"
                                  class="mb-2"
                                  v-if="sub_service.assignee_details.length"
                                >
                                  Assignees
                                </h5>

                                <v-chip
                                  class="no_assignees_chip"
                                  v-if="
                                    sub_service.assignee_details.length === 0
                                  "
                                  outlined
                                >
                                  No assignees
                                </v-chip>

                                <v-chip
                                  v-for="(
                                    assignedSupplier, index
                                  ) in sub_service.assignee_details"
                                  close
                                  :key="index"
                                  @click:close="
                                    unassignSubService(
                                      sub_service.id,
                                      assignedSupplier.id
                                    )
                                  "
                                  class="mx-1 mt-1 assignee-v-chip-color"
                                  >{{ assignedSupplier.name }}
                                </v-chip>
                                <v-progress-linear
                                  v-if="
                                    selectedSubServiceId == sub_service.id &&
                                    unassigning
                                  "
                                  class="mt-1"
                                  color="#473068"
                                  indeterminate
                                  rounded
                                  height="6"
                                ></v-progress-linear>
                              </div>
                            </v-col>
                            <v-col class="assign_supplier_content">
                              <v-select
                                color="#473068"
                                outlined
                                dense
                                loader-height="5"
                                class="mt-5 text-field-width"
                                v-model="selectedSupplier"
                                :items="
                                  getUnassignedSuppliers(
                                    sub_service.assignee_details
                                  )
                                "
                                item-text="name"
                                item-value="id"
                                label="Add accountable company"
                                no-data-text="No suppliers left to add"
                                multiple
                                @blur="assignSubService(sub_service.id)"
                                :loading="
                                  selectedSubServiceId == sub_service.id &&
                                  supplierLoading
                                    ? true
                                    : false
                                "
                              >
                                <template v-slot:selection="{}"></template>
                              </v-select>
                            </v-col>
                            <v-col>
                              <v-text-field
                                :key="`deadline-${
                                  deadlineSelectionKeyToggle ? 1 : 2
                                }`"
                                color="#473068"
                                dense
                                outlined
                                loader-height="5"
                                v-model="newDeadline"
                                @change="changeDeadline(sub_service)"
                                type="date"
                                hint="Please select a date which is not lower than the timeline starting date or the previous subtask completed date or the deadline"
                                class="mt-5 text-field-width"
                                label="Change Deadline"
                                :loading="deadlineUpdatingId === sub_service.id"
                                :disabled="
                                  sub_service.status === 3 ||
                                  deadlineUpdatingId ||
                                  timePeriodUpdatingId
                                "
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </template>
                </v-stepper>
              </div>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import range from "lodash/range";
import momentBusiness from "moment-business-days";

export default {
  name: "Timeline",
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
    initialStep: {
      type: Number,
      default: null,
    },
    collectionId: {
      type: [String, Number],
      required: true,
    },
    refreshTimeline: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.loading = true;
    if (this.collectionId) {
      await this.fetchTimeline();
      await this.fetchCollectionUsers()
        .then()
        .catch((error) => (this.loading = false));
    }
    this.loading = false;
    if (!this.minimal) this.setInitialStepper();
  },
  data: () => ({
    timeline: [],
    completedPercentages: [],
    suppliers: [],
    selectedSupplier: null,
    stepper: 0,
    supplierLoading: false,
    changingStep: false,
    unassigning: false,
    selectedSubServiceId: null,
    subStepper: 0,
    newDeadline: null,
    deadlineSelectionKeyToggle: false,
    selectedStatus: null,
    changingStatus: false,
    minimalStyle: {
      fontSize: "12px",
      width: "80px",
    },
    statuses: [
      { text: "Not Started", value: 1 },
      { text: "In Progress", value: 2 },
      { text: "Completed", value: 3 },
    ],
    loading: false,
    statusUpdatingId: null,
    timePeriodUpdatingId: null,
    deadlineUpdatingId: null,
    timePeriod: null,
    newTimePeriod: null,
  }),
  watch: {
    refreshTimeline: {
      handler: function () {
        this.loading = true;
        this.fetchTimeline();
        this.fetchCollectionUsers()
          .then(() => (this.loading = false))
          .catch(() => (this.loading = false));
      },
    },
    initialStep: {
      handler: function () {
        this.setInitialStepper();
      },
    },
  },
  methods: {
    clickEvent(stepper, key) {
      if (this.minimal) return false;
      if (stepper === key + 1) {
        return true;
      } else return false;
    },
    onStepperClick(key) {
      if (!this.minimal) {
        this.subStepper = 0;
        this.selectedSupplier = null;
        this.selectedStatus = null;
        this.stepper === key + 1;
      }
      this.$emit("stepper-click", key + 1);
    },
    checkDeadlinePassedCloseOrCompleted(group, key) {
      const returnDetails = {
        passed: {
          status: "passed",
          class: "deadline_passed_inside_circle",
          color: "#FC3766",
        },
        close: {
          status: "close",
          class: "deadline_close_inside_circle",
          color: "#EA7A66",
        },
        completed: {
          status: "completed",
          class: "completed_inside_circle",
          color: "#70D0CE",
        },
        default: {
          status: "default",
          class: "completed_inside_circle",
          color: "#70D0CE",
        },
      };

      if (this.completedPercentages[key] === 100) {
        return returnDetails.completed;
      }

      const deadline = this.$moment(
        group.services.at(-1).deadline,
        "YYYY-MM-DD"
      );

      const startDate = this.$moment(
        this.getGroupStartDate(group),
        "YYYY-MM-DD"
      );

      const today = this.$moment().format("YYYY-MM-DD");

      const totalDays = deadline.diff(startDate, "days");

      const elapsedTime = deadline.diff(today, "days");

      if (elapsedTime < 0) {
        return returnDetails.passed;
      }

      if (this.completedPercentages[key] !== 100) {
        if (Math.abs(elapsedTime) <= (totalDays * 30) / 100) {
          return returnDetails.close;
        }
      }

      return returnDetails.default;
    },
    calculateDaysTaken(group, key) {
      if (this.completedPercentages[key] === 100) {
        return "Completed";
      }

      const isStarted = group.services.some((service) => {
        return service.sub_services.some(
          (subService) => subService.status === 2 || subService.status === 3
        );
      });

      if (!isStarted) {
        return "Not started";
      }

      const startedAt = momentBusiness(
        this.getGroupStartDate(group),
        "YYYY-MM-DD"
      );

      const today = this.$moment(this.$moment(), "YYYY-MM-DD");

      const daysTaken = today.diff(startedAt, "days");
      return daysTaken === 0
        ? "Today"
        : daysTaken === 1
        ? "1 day"
        : daysTaken + " days";
    },
    getGroupStartDate(group) {
      const additionalSubtraction = group.order === 1 ? 1 : 0; // Because timeline starts after one day of collection creation
      let startDate;
      for (let i = 0; i < group.services.length; i++) {
        if (group.services[i].sub_services.length) {
          startDate = momentBusiness(
            group.services[i].sub_services[0].deadline,
            "YYYY-MM-DD"
          ).businessSubtract(
            group.services[i].sub_services[0].time_period +
              additionalSubtraction
          )._d;
          break;
        }
      }
      return startDate;
    },
    checkDeadlinePassedSubService(service) {
      const deadline = this.$moment(service.deadline, "YYYY-MM-DD");
      const completedDateOrToday = service.completed_at
        ? this.$moment(service.completed_at, "YYYY-MM-DD").format("YYYY-MM-DD")
        : this.$moment().format("YYYY-MM-DD");

      const diff = deadline.diff(completedDateOrToday, "days");

      if (diff < 0) {
        return true;
      }

      return false;
    },
    subStepperClick(deadline, timePeriod, key, subServiceId) {
      this.selectedSubServiceId = subServiceId;
      this.subStepper === key + 1 && (this.subStepper = 0);
      this.selectedSupplier = null;
      this.newDeadline = deadline;
      this.timePeriod = timePeriod;
      this.newTimePeriod = timePeriod;
    },
    changeTimePeriod(subService, markTimePeriodUpdating = true) {
      if (this.newTimePeriod === subService.time_period) {
        return;
      }

      if (markTimePeriodUpdating) {
        this.timePeriodUpdatingId = subService.id;
        const startDate = momentBusiness(
          this.$moment(subService.deadline).format("YYYY-MM-DD"),
          "YYYY-MM-DD"
        ).businessSubtract(subService.time_period);

        const newDeadline = startDate.businessAdd(this.newTimePeriod);
        this.newDeadline = this.$moment(newDeadline).format("YYYY-MM-DD");
      } else {
        this.deadlineUpdatingId = subService.id;
      }

      restAdapter
        .post(
          `/api/collections/timeline/collection_sub_service/${subService.id}/time_period`,
          {
            time_period: this.newTimePeriod,
          }
        )
        .then(() => {
          if (markTimePeriodUpdating) {
            this.timePeriodUpdatingId = null;
            notification.success("Time period updated successfully.");
          } else {
            this.deadlineUpdatingId = null;
            notification.success("Deadline updated successfully.");
          }
          this.fetchTimeline();
          this.notifyCard();
        });
    },
    changeDeadline(subService) {
      const newDeadline = momentBusiness(
        this.$moment(this.newDeadline).format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      if (!newDeadline.isBusinessDay()) {
        notification.error("Please select a week day.");
        this.newDeadline = subService.deadline;
        this.deadlineSelectionKeyToggle = !this.deadlineSelectionKeyToggle;
        return;
      }

      const startDate = momentBusiness(
        this.$moment(subService.deadline).format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      ).businessSubtract(subService.time_period);

      const newTimePeriod = newDeadline.businessDiff(startDate, true);

      if (newTimePeriod < 0) {
        notification.error(
          "Please select a date which is not lower than the timeline starting date or the previous subtask completed date or the deadline"
        );
        this.newDeadline = subService.deadline;
        this.deadlineSelectionKeyToggle = !this.deadlineSelectionKeyToggle;
        return;
      }

      this.newTimePeriod = newTimePeriod;
      this.changeTimePeriod(subService, false);
    },
    changeStatus(value, subServiceId) {
      this.selectedStatus = value;
      this.updateSubServiceStatus(subServiceId);
    },
    getUnassignedSuppliers(assignedSuppliers) {
      const assignedSupplierIds = assignedSuppliers.map((item) => item.id);

      const unassignedSuppliers = this.suppliers.filter((supplier) => {
        return !assignedSupplierIds.includes(supplier.id);
      });
      return unassignedSuppliers;
    },
    formatDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
    formatTime(date) {
      return this.$moment(date).format("HH:mm Do MMMM YYYY");
    },
    formatTimeAgo(date) {
      return this.$moment(date).fromNow();
    },
    async fetchCollectionUsers() {
      const response = await restAdapter.get(
        `/api/collections/${this.collectionId}/organizations_list`
      );
      this.suppliers = response.data;
    },
    async fetchTimeline() {
      const response = await restAdapter.get(
        `/api/collections/${this.collectionId}/timeline`
      );
      this.timeline = response.data.groups;

      this.unassigning = false;
      this.supplierLoading = false;
      this.changingStep = false;
      this.changingStatus = false;
      this.calculateCompletedPercentages();
    },
    calculateCompletedPercentages() {
      this.completedPercentages = this.timeline.map((group) => {
        const totalSubServices = group.services.reduce(
          (acc, service) => acc + service.sub_services.length,
          0
        );

        const completedSubServices = group.services.reduce(
          (acc, service) =>
            acc +
            service.sub_services.filter((subService) => subService.status === 3)
              .length,
          0
        );

        const percentage = Math.round(
          (completedSubServices / totalSubServices) * 100
        );

        return !isNaN(percentage) ? percentage : 0;
      });
    },
    setInitialStepper() {
      if (this.initialStep) {
        this.stepper = this.initialStep;
        return;
      }

      for (let index = 0; index < this.completedPercentages.length; index++) {
        if (this.completedPercentages[index] !== 100) {
          this.stepper = index + 1;
          break;
        }

        if (index === this.completedPercentages.length - 1) {
          this.stepper = this.completedPercentages.length;
        }
      }
    },
    async assignSubService(subserviceId) {
      this.selectedSubServiceId = subserviceId;
      if (this.selectedSupplier !== null) {
        this.supplierLoading = true;
      }

      this.selectedSupplier.forEach(async (item) => {
        const response = await restAdapter
          .post(
            `/api/collections/timeline/collection_sub_service/${subserviceId}/assign`,
            {
              assignee: item,
            }
          )
          .then((res) => {
            notification.success("Accountable company added successfully.");
            this.fetchTimeline();
            this.notifyCard();
          })
          .catch((error) => {
            notification.error(error.response.data.error);
          });
      });

      this.selectedSupplier = null;
    },
    async unassignSubService(subserviceId, supplierId) {
      this.selectedSubServiceId = subserviceId;
      this.unassigning = true;
      const response = await restAdapter
        .delete(
          `/api/collections/timeline/collection_sub_service/${subserviceId}/remove_assignment`,
          {
            assignee: supplierId,
          }
        )
        .then((res) => {
          notification.success("Accountable company removed successfully.");
          this.fetchTimeline();
        })
        .catch((error) => {
          notification.error(error.response.data.error);
          this.unassigning = false;
        });
      this.notifyCard();
    },
    async updateSubServiceStatus(id) {
      this.statusUpdatingId = id;
      try {
        const response = await restAdapter.post(
          `/api/collections/timeline/collection_sub_service/${id}/updateStatus`,
          {
            status: this.selectedStatus,
          }
        );
      } catch (error) {
        notification.error(error.response.data.message);
        this.fetchTimeline();
        this.selectedStatus = null;
        this.selectedStatus = null;
        this.statusUpdatingId = null;
        return null;
      }
      notification.success("Status updated successfully.");
      this.fetchTimeline();
      this.selectedStatus = null;
      this.statusUpdatingId = null;
      this.notifyCard();
      this.notifyDashboard();
    },
    notifyDashboard() {
      this.$emit("reload-dashboard");
    },
    notifyCard() {
      this.$emit("reload-card");
    },
    isAllSubCompleted(services) {
      return services.every((service) => service.is_completed);
    },
    numberList() {
      return range(0, 1000 + 1);
    },
  },
};
</script>
<style lang="scss">
.v-stepper__header .v-stepper__step__step {
  display: none !important;
}
.clickedService {
  background: #eef1fa !important;
  border-radius: 5px;
  .main-service-header {
    color: #473068;
  }
}
.theme--light.v-stepper--non-linear
  .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__label {
  color: #473068;
}
.loading-bar-container {
  width: 92.97px;
  text-align: left;
  color: $primaryDarkest;
  font-weight: 500;
  margin: 5px;
}
.v-stepper__header .v-stepper__step {
  align-items: flex-start;
  padding: 5px;
}
.v-stepper__header {
  height: auto !important;
  margin-top: 5px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.v-stepper__header .v-divider {
  border-width: 2px !important;
  border-color: #442d65 !important;
  background: #442d65;
  margin-bottom: 53px !important;
  border-width: 2px !important;
}
.v-stepper__header .v-divider.minimal {
  border-width: 1px !important;
  border-color: #442d65 !important;
  background: #442d65;
  margin-bottom: 30px !important;
  border-width: 1px !important;
}
.v-stepper--vertical .v-stepper__content {
  border-left: 4px solid #70d0ce !important;
  margin: -8px -36px -16px 47px !important;
  height: auto !important;
  border-width: 3px !important;
}
.v-stepper__step__step.primary {
  background: white !important;
  border: solid 8px #2196f3 !important;
}
.v-stepper--vertical .v-stepper__step__step {
  display: none !important;
  height: 50px !important;
  font-size: 15px !important;
  color: #2196f3 !important;
  background: white !important;
  border: solid 8px #2196f3 !important;
}
.v-icon.v-icon::after {
  background-color: white !important;
}
.main-service-header {
  display: grid;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #473068;
  width: 100px;
}
.main-service-header.minimal {
  font-size: 12px;
  line-height: 12px;
  width: 80px;
}
.sub-service-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-menu {
  z-index: 1;
}
.days {
  display: flex;
  justify-content: space-around;
}
.v-stepper__content {
  top: 0;
  padding: 0;
  flex: 1 0 auto;
  width: 100%;
}
.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: flex;
}
.display-icons {
  color: #70d0ce !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  font-weight: lighter;
}
.v-stepper__label {
  display: flex;
}
.sub-service-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 116.1%;
  color: #442d65;
}
.sub-service-date {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 116.1%;
  color: #473168 !important;
  display: flex;
}
.text-field-width {
  color: #473068;
  width: 180px !important;
}
.mdi-check {
  color: #70d0ce !important;
  font-size: 25px !important;
}
@media only screen and (min-width: 768px) {
  .sub-service-header-text {
    width: 195px;
    display: grid;
    margin-left: 15px;
    margin-right: 30px;
  }
  .service-header {
    text-align: left;
    color: #473068;
    font-weight: 900;
    font-size: medium;
    margin: 20px 0px 10px 0px;
  }
  .sub-service-name {
    display: unset;
  }
  .text-field-width {
    width: 90% !important;
  }
  .v-card .v-card--raised {
    margin-top: 0 !important;
  }
}
.md-progress-spinner.md-theme-default .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
.v-chip-color {
  font-style: normal;
  font-weight: 400;
  line-height: 116.1%;
  height: 27px !important;
  color: #473168 !important;
  background: rgba(200, 180, 230, 0.12) !important;
  border: 1px solid #473068 !important;
  border-radius: 46px;
}
.assignee-v-chip-color {
  font-style: normal;
  font-weight: 400;
  line-height: 116.1%;
  min-height: 27px;
  height: auto !important;
  color: #473068 !important;
  background: #faf7ff !important;
  border: 1px solid #473068 !important;
  border-radius: 46px;
  padding-top: 5px;
  text-wrap: wrap;
  padding-bottom: 5px;
}
.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
  color: #473068 !important;
}
.sub_task_number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 9px;
  background: rgba(71, 48, 104, 0.1);
  border-radius: 50px;
  margin-left: auto;
  padding-top: 1px;
}
.subtask_titles {
  padding-top: 18px;
  font-style: normal;
  font-weight: 600;
  color: #442d65;
}
.dates_container {
  text-align: left;
}
.status_alignment {
  margin-top: 1px;
}
.subservices_dates_titles {
  text-align: left;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 116.1%;
  align-items: center;
  color: #442d65;
}
.subservices_dates {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 116.1%;
  color: #473168;
}
.deadline_passed_inside_circle {
  color: #fc3766;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 30px;
  z-index: 0;
  border: 1px solid #ffffff;
  box-shadow: -3px 4px 4px 0px rgb(252 55 102 / 33%);
  backdrop-filter: blur(5px);
}
.deadline_close_inside_circle {
  color: #ea7a66;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 30px;
  z-index: 0;
  border: 1px solid #ffffff;
  box-shadow: -3px 4px 4px 0px rgb(234 122 102 / 33%);
  backdrop-filter: blur(5px);
}
.completed_inside_circle {
  color: #70d0ce;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 30px;
  z-index: 0;
  border: 1px solid #ffffff;
  box-shadow: -3px 4px 4px 0px rgb(112 208 206 / 33%);
  backdrop-filter: blur(5px);
}
.v-progress-circular > svg {
  border: 1px solid #ffffff;
  border-radius: 50px;
}
.v-progress-circular__underlay {
  stroke: #f3f3f3 !important;
  z-index: 1;
}
.v-stepper__header {
  box-shadow: none !important;
}
.v-stepper {
  box-shadow: none !important;
}
.timeline_container {
  box-shadow: none !important;
  background-color: #ffffff !important;
}
.no-activities-parent {
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
}
.no-activities-text {
  font-size: 14px;
  color: gray;
  text-align: left;
  width: 100%;
  padding-left: 90px;
  opacity: 0.5;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}
.subtasks_circulars_container {
  margin: 18px;
  background-color: #f0f0f4 !important;
  box-shadow: none !important;
}
.no_assignees_chip {
  margin-top: 25px;
  width: 150px;
  height: 32px !important;
  justify-content: center;
  color: #ea7a66 !important;
  border-color: #ea7a66 !important;
}
.theme--light.v-stepper .v-stepper__step--editable:hover {
  background: #eef1fa !important;
  border-radius: 5px;
}
.theme--light.v-label {
  color: #473068;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #473068;
}
.theme--light.v-messages {
  color: #473068;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #473068;
}
.assign_supplier_content::v-deep .v-menu__content {
  padding-top: 40px;
  margin-top: 0px;
  color: #ea7a66 !important;
  position: absolute;
  display: inline-block;
  width: 180px !important;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #473068 !important;
}
.theme--light.v-list {
  background: #f3edf7 !important;
}
.sub-service-content {
  padding-bottom: 15px;
}
</style>

<style lang="scss" scoped>
.bar_color::v-deep {
  .md-progress-bar-track:after {
    background-color: #582963 !important;
  }
  .md-progress-bar-fill:after {
    background-color: #582963 !important;
  }
}
.md-progress-bar.md-theme-default.md-indeterminate,
.md-progress-bar.md-theme-default.md-query {
  background-color: #c6a8ec !important;
  background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
}
</style>
